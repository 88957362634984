import { useNavigate } from 'react-router-dom'
import './Step6.scss'
import { useDispatch, useSelector } from 'react-redux'
import RegistrationImage from 'assets/images/registration-image.png'
import RegistrationImageFR from 'assets/images/registration-image-fr.png'
import { texts } from 'utils/localizedTexts'
import { resetUserSlice } from 'redux/slices/user'
import { useEffect } from 'react'
import axios from 'utils/api'
import Layout from 'components/Layout/Layout'

const Step6 = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const lang = useSelector(state => state.lang)
	const sendEmail = async () => {
		try {
			const data = await axios.get(`/apps/sendEmail/${user.id}`)
			console.log('email was sent successfully')
		} catch (err) {
			console.log(err)
		}
	}
	useEffect(() => {
		sendEmail()
	}, [])
	return (
		<Layout buttons={false}>
			<div className='Step6'>
				<h1 className='Step6__header c-main-header'>
					{texts[lang].step6.header}
				</h1>

				<p className='Step6__subheader'>
					{/* <b>{texts[lang].step6.boldLine1}</b> */}
					{texts[lang].step6.boldLine1}

					{texts[lang].step6.line2}
				</p>
				<h6>{texts[lang].step6.lastLine}</h6>

				<div className='Step6__buttons'>
					<button
						className='c-main-btn'
						onClick={() => {
							navigate('/step8')
						}}>
						{texts[lang].step6.button}
					</button>
				</div>
			</div>
		</Layout>
	)
}

export default Step6
