import './Layout.scss'
import BackgroundImage1 from 'assets/images/background.png'
import BackgroundV2 from 'assets/images/backgroundV2.png'

import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useWindowSize } from 'hooks/useWindowSize'
import GoodLifeLogo from 'components/icons/GoodLifeLogo'
import ArrowIcon from 'components/icons/ArrowIcon'
import lang from 'redux/slices/lang'
import InfoIcon from 'components/icons/InfoIcon'
import SettingIcon from 'components/icons/SettingIcon'
import CloseModal from 'assets/images/close-modal.svg'
import AngleArrow from 'components/icons/AngleArrow'
import { useSelector } from 'react-redux'

const Layout = ({
	isHome = false,
	children,
	style = {},
	prevBtn = null,
	nextBtn = null,
	buttons = true,
	isPagination = true
}) => {
	const location = useLocation()
	const [toggleConfetti, setToggleConfetti] = useState(false)
	const [toggleInfo, setToggleInfo] = useState(false)
	const [width, height] = useWindowSize()
	const navigate = useNavigate()
	const lang = useSelector(state => state.lang)


	useEffect(() => {
		if (location.pathname.includes('step6')) {
			setToggleConfetti(true)
		} else setToggleConfetti(false)
	}, [location])
	return (
		<div style={style} className={`Layout ${isHome && 'bg-image-background '}`}>
			{!isHome && (
				<img
					className='Layout__bg-image-1'
					src={BackgroundImage1}
					alt='bg image'
				/>
			)}

			{!isHome && (
				<div className='Layout__logo'>
					<button onClick={() => setToggleInfo(true)}>
						<InfoIcon />
					</button>

					<GoodLifeLogo />

					<button onClick={() => navigate('/settings')}>
						<SettingIcon />
					</button>
				</div>
			)}

			{isHome ? (
				<div>{children}</div>
			) : (
				<>
					<div className='Layout__content'>{children}</div>
					{buttons && (
						<div style={{ marginTop: '90px' }} className='Layout__buttons'>
							{prevBtn ? (
								<button className='c-main-btn' onClick={prevBtn}>
									<AngleArrow />
									{lang === 'en' ? 'BACK' : 'RETOUR'}
								</button>
							) : (
								<span></span>
							)}
							{isPagination && (
								<ul className='pagination'>
									{[1, 1, 1, 1, 1].map((item, index) => (
										<li
											className={
												Number(location.pathname.split('p')[1]) === index + 1
													? 'active'
													: ''
											}></li>
									))}
								</ul>
							)}

							{nextBtn ? (
								<button
									onClick={nextBtn}
									className='c-main-btn'
									type='submit'
									form='user-info'>
									{lang === 'en' ? 'NEXT' : 'SUIVANT'}{' '}
									<AngleArrow className={'next-arrow'} />
								</button>
							) : (
								<span></span>
							)}
						</div>
					)}
				</>
			)}

			{toggleConfetti && (
				<Confetti
					width={width}
					height={height}
					numberOfPieces={1000}
					recycle={false}
					onConfettiComplete={() => {}}
				/>
			)}

			{toggleInfo && (
				<div className='Layout__prize-modal'>
					<div className='Layout__prize-modal--content'>
						<div className='close-button'>
							<h2 className='subheader'>
								<InfoIcon />
								Information
							</h2>
							<img
								src={CloseModal}
								alt='close modal'
								onClick={() => setToggleInfo(false)}
							/>
						</div>
						<button
							className='modal-button c-main-btn'
							onClick={() => {
								setToggleInfo(false)
								navigate('/step3')
							}}>
							LEAD FORM
						</button>
						<button
							className='modal-button c-main-btn'
							onClick={() => {
								setToggleInfo(false)
								navigate('/step8')
							}}>
							SCRATCHER
						</button>
						<button
							className='modal-button c-main-btn'
							onClick={() => {
								setToggleInfo(false)
								navigate('/settings')
							}}>
							CLOSING REPORT
						</button>
					</div>
				</div>
			)}
		</div>
	)
}

export default Layout
