import { useNavigate } from 'react-router-dom'
import './Step4.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setFitnessGoal, setIsFemale, setIsValued } from 'redux/slices/user'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'utils/api'
import { texts } from 'utils/localizedTexts'
import ArrowIcon from 'components/icons/ArrowIcon'
import Layout from 'components/Layout/Layout'

const Step4 = () => {
	const [activeStep, setActiveStep] = useState(1)
	const { isValued, fitnessGoal } = useSelector(state => state.user)
	const lang = useSelector(state => state.lang)
	const [lastStepToggle, setLastStepToggle] = useState('')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [isSubmittable, setIsSubmittable] = useState(false)
	const isFemaleHandler = value => {
		dispatch(setIsFemale(value))
		setActiveStep(2)
	}
	const fitnessGoalHandler = value => {
		dispatch(setFitnessGoal(value))
	}
	const isValuedHandler = value => {
		dispatch(setIsValued(value))
		setIsSubmittable(true)
	}
	const putFurtherValues = async () => {
		const user = localStorage.getItem('persist:root')
		const userInfo = await JSON.parse(user)
		const { id, fitnessGoal } = await JSON.parse(userInfo.user)
		try {
			const { data } = await axios.put('/apps/completeLeadRegister', {
				id,
				goal: fitnessGoal,
				env: 'Site'
			})
			navigate('/step5')
		} catch (err) {
			console.log(err)
			toast(err.response.data.message)
		}
	}

	const nextStep = async () => {
		const user = localStorage.getItem('persist:root')
		const userInfo = await JSON.parse(user)
		const { fitnessGoal } = await JSON.parse(userInfo.user)
		if (fitnessGoal === '' || !fitnessGoal) {
			toast('please select one of the fitness Goal')
		} else {
			navigate('/step5')
		}
	}

	return (
		<Layout nextBtn={() => nextStep()} prevBtn={() => navigate('/step3')}>
			<div className='Step4'>
				{/* {activeStep === 1 && (
				<div>
					<h1 className='Step4__header c-main-header'>
						{texts[lang].step4.womenHeader}
					</h1>
					<div className='Step4__options'>
						<div
							className={`Step4__options--option c-main-option`}
							onClick={() => isFemaleHandler(true)}>
							{lang === 'en' ? 'YES' : 'OUI'}
						</div>
						<div
							className='Step4__options--option c-main-option'
							onClick={() => isFemaleHandler(false)}>
							{lang === 'en' ? 'NO' : 'NON'}
						</div>
					</div>
				</div>
			)} */}
				{/* {activeStep === 2 && ( */}
				<>
					<h1 className='Step4__header c-main-header'>
						{texts[lang].step4.goals.header}
					</h1>
					<div className='Step4__options'>
						<div
							className={`Step4__options--option c-main-option sm ${
								lang === 'fr' && 'fr_option_button'
							} ${fitnessGoal === 'INCREASE ENERGY' && 'active'}`}
							onClick={() => fitnessGoalHandler('INCREASE ENERGY')}>
							{texts[lang].step4.goals.energy}
						</div>
						<div
							className={`Step4__options--option c-main-option sm ${
								lang === 'fr' && 'fr_option_button'
							} ${fitnessGoal === 'INCREASE STRENGTH' && 'active'}`}
							onClick={() => fitnessGoalHandler('INCREASE STRENGTH')}>
							{texts[lang].step4.goals.strength}
						</div>
						<div
							className={`Step4__options--option c-main-option sm ${
								lang === 'fr' && 'fr_option_button'
							} ${fitnessGoal === 'LOSE WEIGHT' && 'active'}`}
							onClick={() => fitnessGoalHandler('LOSE WEIGHT')}>
							{texts[lang].step4.goals.weight}
						</div>
						<div
							className={`Step4__options--option c-main-option sm ${
								lang === 'fr' && 'fr_option_button'
							} ${fitnessGoal === 'ALL OF THE ABOVE' && 'active'}`}
							onClick={() => fitnessGoalHandler('ALL OF THE ABOVE')}>
							{texts[lang].step4.goals.all}
						</div>
					</div>
					<br />
				</>
				{/* )}
			{activeStep === 3 && (
				<div>
					<h1 className='Step4__header c-main-header'>
						{texts[lang].step4.complimentary}
					</h1>
					<div className='Step4__options'>
						<div
							className={`Step4__options--option c-main-option ${
								lastStepToggle === 'yes' ? 'active' : ''
							}`}
							onClick={() => {
								setLastStepToggle('yes')
								isValuedHandler(true)
							}}>
							{lang === 'en' ? 'YES' : 'OUI'}
						</div>
						<div
							className={`Step4__options--option c-main-option ${
								lastStepToggle === 'no' ? 'active' : ''
							}`}
							onClick={() => {
								setLastStepToggle('no')
								isValuedHandler(false)
							}}>
							{lang === 'en' ? 'NO' : 'NON'}
						</div>
					</div>
				</div>
			)} */}

				<ToastContainer />
			</div>
		</Layout>
	)
}

export default Step4
