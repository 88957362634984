import { useNavigate } from 'react-router-dom'
import './Step1.scss'
import { useDispatch, useSelector } from 'react-redux'
import { texts } from 'utils/localizedTexts'
import axios from 'utils/api'
import { setEventInfo } from 'redux/slices/eventInfo'
import Loading from 'components/Loading/Loading'
import { useEffect, useState } from 'react'
import ArrowIcon from 'components/icons/ArrowIcon'
import Layout from 'components/Layout/Layout'
import { setIsMember } from 'redux/slices/user'

const Step2 = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const lang = useSelector(state => state.lang)

	const dispatchHandler = value => {
		dispatch(setIsMember(value))
		navigate('/step3')
	}
	return (
		<Layout prevBtn={() => navigate('/step1')}>
			<div className='Step3'>
				<h1
					dangerouslySetInnerHTML={{ __html: texts[lang].step2.header }}
					className='Step3__header c-main-header'></h1>
				<div className='Step3__options'>
					<div
						className='Step3__options--option c-main-option'
						onClick={() => dispatchHandler(true)}>
						{lang === 'en' ? 'YES' : 'OUI'}
					</div>
					<div
						className='Step3__options--option c-main-option'
						onClick={() => dispatchHandler(false)}>
						{lang === 'en' ? 'NO' : 'NON'}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Step2
