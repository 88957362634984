import axios from 'axios'
import ArrowIcon from 'components/icons/ArrowIcon'
import Layout from 'components/Layout/Layout'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { setIsMember } from 'redux/slices/user'
import { texts } from 'utils/localizedTexts'
import './Step5.scss'

const Step5 = () => {
	const [status, setStatus] = useState('known')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const dispatchHandler = value => {
		dispatch(setIsMember(value))
		putFurtherValues()
	}
	const lang = useSelector(state => state.lang)

	const putFurtherValues = async () => {
		const user = localStorage.getItem('persist:root')
		const userInfo = await JSON.parse(user)
		const { id, fitnessGoal } = await JSON.parse(userInfo.user)
		if (status === 'known') {
			toast('please select one of the status')
			return
		}
		try {
			const { data } = await axios.put('/apps/completeLeadRegister', {
				id,
				approx_value: status ? 'Yes' : 'No',
				goal: fitnessGoal,
				env: 'App'
			})
			navigate('/step6')
		} catch (err) {
			console.log(err)
			toast(err.response.data.message)
		}
	}

	return (
		<Layout
			nextBtn={() => putFurtherValues()}
			prevBtn={() => navigate('/step4')}>
			<div className='Step5'>
				<h1
					dangerouslySetInnerHTML={{ __html: texts[lang].step5.header }}
					className='Step5__header c-main-header'></h1>
				<div className='Step5__options'>
					<div
						className='Step5__options--option c-main-option'
						onClick={() => setStatus(true)}>
						{lang === 'en' ? 'YES' : 'OUI'}
					</div>
					<div
						className='Step5__options--option c-main-option'
						onClick={() => setStatus(false)}>
						{lang === 'en' ? 'NO' : 'NON'}
					</div>
				</div>
				<div className={`content ${lang === 'fr' && 'fr'}`}>
					<p>{texts[lang].step5.contentOne}</p>
					<p>
						{texts[lang].step5.contentTwo}{' '}
						<a href='https://www.goodlifefitness.com/'>
							{texts[lang].step5.contentLink}
						</a>
						<br />
						{texts[lang].step5.contentThree}{' '}
					</p>
				</div>

				<ToastContainer />
			</div>
		</Layout>
	)
}

export default Step5
