import { Link, useNavigate } from 'react-router-dom'
import './Step1.scss'
import { useDispatch, useSelector } from 'react-redux'
import { texts } from 'utils/localizedTexts'
import axios from 'utils/api'
import { setEventInfo } from 'redux/slices/eventInfo'
import Loading from 'components/Loading/Loading'
import { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'

const Step1 = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const lang = useSelector(state => state.lang)

	const questionHandler = status => {
		status ? navigate('/step2') : navigate('/')
	}

	return (
		<Layout prevBtn={null} nextBtn={null}>
			<div className='Step3'>
				<h1
					dangerouslySetInnerHTML={{ __html: texts[lang].step1.header }}
					className='Step3__header c-main-header'></h1>
				<div className='Step3__options'>
					<div
						className='Step3__options--option c-main-option'
						onClick={() => questionHandler(true)}>
						{lang === 'en' ? 'YES' : 'OUI'}
					</div>
					<Link
						to='/home'
						className='Step3__options--option c-main-option'>
						{lang === 'en' ? 'NO' : 'NON'}
					</Link>
				</div>
			</div>
		</Layout>
	)
}

export default Step1
