export const texts = {
	en: {
		home: {
			title: 'YOU COULD WIN $10,000',
			subtitle: 'IN PERSONAL TRAINING',
			prizeInfo:
				'Prize includes TWO 1-year \n GoodLife Fitness gym memberships.',
			button: 'ENTER NOW',
			description:
				'*No purchase necessary to enter the Contest. Contest begins January 10, 2023 and ends on December 31, 2023. Contest open to residents of Canada other than Quebec who are not employees of GoodLife Fitness Centres Inc. or Influence Retail Services Inc. To participate, complete the on-screen instructions via iPad at select GoodLife sponsored events or go to www.GLMembership.ca to complete the Entry Form. The names of all valid participants will be entered into a Grand Prize draw for a chance to win 2, 1-year ‘All Club including Group Fitness’ Memberships to GoodLife Fitness with an approximate retail value of $1,000, as well as GoodLife personal training sessions valued at $10,000 CAD. Grand Prize Draw will take place on January 5, 2024 and the Winner will be contacted on that date. Other conditions apply. Visit www.GLMembership.ca for more details and for full rules and regulations. This Contest is administered by Influence Retail Services Inc. who retains sole responsibility of the Contest.			'
		},
		step1: {
			header:
				'Please confirm age of majority.<br /> Are you over the age of 18?'
		},
		step2: {
			header: 'Are you currently a <br /> GoodLife Fitness member?'
		},
		step3: {
			formHeader: 'To enter the contest, please fill out your details below:',
			formPlaceholders: {
				firstName: 'First Name',
				lastName: 'Last Name',
				email: 'Email',
				postcode: 'Postal Code',
				phone: 'Phone Number'
			},
			emailAgreementText:
				'I agree to receive GoodLife emails — which can include news on Clubs and Memberships, promotions (including the Grand Prize draw), and special offers. I can opt out of receiving any of these communications at any time.',
			rulesHeader: {
				text: 'I have read and agree to the',
				link: 'Rules & Regulations'
			}
		},
		step4: {
			womenHeader: 'Are you interested in For Women Clubs?',
			goals: {
				header: 'What is your fitness goal?',
				energy: 'INCREASE ENERGY',
				strength: 'INCREASE STRENGTH',
				weight: 'LOSE WEIGHT',
				all: 'ALL OF THE ABOVE'
			},
			complimentary: 'Are you interested in a complimentary 7-day pass?'
		},
		step5: {
			header: 'Are you interested in a <br /> complimentary 7-day pass?',
			contentOne:
				'* By clicking "Yes" you are consenting to GoodLife contacting you, by telephone, SMS message or e-mail address for the purpose of sending you marketing, special offers and other communications that might be of interest to you. You may opt out at any time.',
			contentTwo:
				'** Once you submit your request, GoodLife will contact you to schedule an appointment for a Club tour. Visitors must be of the age of majority in the province in which the Club is located (unless accompanied by their parent or legal guardian) and may only attend during staffed Club hours. Non-transferable. No cash value. To redeem your free workout, on completion of the Club tour, a GoodLife Associate will book you in for a free workout session, which must occur within 1 week of the Club tour. A guest liability waiver will need to be signed upon arriving for your free workout. Other conditions may apply.',
			contentLink: 'goodlifefitness.com',
			contentThree:
				' or call one of our Clubs for details and for hours of operation.'
		},

		step6: {
			header: 'Thank you for registering!!',
			boldLine1:
				'You have been entered into the contest. If you were interested in becoming a GoodLife Member or learning more about our Clubs, an Associate will be in touch with you.',
			lastLine: 'A confirmation has been sent to your email.',
			button: 'play “scratch to win!”'
		},
		step8: {
			scratchHeader: 'Scratch away to reveal <br /> your instant prize!',
			wonHeader: "You've won a",
			claimHeader: 'Before you go!',
			claimSubheader: 'Did you claim your prize?',
			claimText:
				'Make sure you get your instant prize from any on-site GoodLife Ambassador.',
			claimButton: 'I’ve claimed my prize'
		}
	},
	fr: {
		home: {
			title: 'VOUS POUrriez gagner $10,000',
			subtitle: 'EN ENTRAINEMENTS PERSONNELS',
			prizeInfo:
				'Le prix inclut DEUX adhésions \n annuelles chez GoodLife Fitness.',
			button: 'Participez maintenant',
			description:
				"Aucun achat n'est nécessaire pour participer au concours. Le concours débute le 10 janvier 2023 et se termine le 31 décembre 2023. Le concours est ouvert aux résidents du Canada, à l'exception du Québec, qui ne sont pas des employés des centres de conditionnement physique GoodLife Inc. ou d'Influence Retail Services Inc. Pour participer, il suffit de suivre les instructions à l'écran à l'aide d'un iPad lors d’événements commandités par GoodLife ou de se rendre à l'adresse www.GLMembership.ca pour remplir le formulaire de participation. Les noms de tous les participants valides seront inscrits au tirage du grand prix pour avoir la chance de gagner deux abonnements d'un an à GoodLife Fitness, d'une valeur au détail approximative de 1 000 $, ainsi que des séances d'entraînement personnel GoodLife d'une valeur de 10 000 $ CAN. Le tirage du grand prix aura lieu le 5 janvier 2024 et le gagnant sera contacté à cette date. D'autres conditions s'appliquent. Visitez le site www.GLMembership.ca pour plus de détails et pour consulter le règlement complet. Ce concours est administré par Influence Retail Services Inc. qui en conserve l'entière responsabilité."
		},
		step1: {
			header:
				"Veuillez confirmer que vous avez atteint l'âge de la majorité.Avez-vous 18 ans ou plus?"
		},
		step2: {
			header: 'Êtes-vous actuellement <br /> un membre GoodLife Fitness?'
		},
		step3: {
			formHeader:
				'Pour participer au concours, veuillez saisir les renseignements ci-dessous.',
			formPlaceholders: {
				firstName: 'Prénom',
				lastName: 'Nom',
				email: 'Courriel',
				postcode: 'Code Postal',
				phone: 'No de tléléphone'
			},
			emailAgreementText:
				"J'accepte de recevoir des courriels de la part de GoodLife Fitness, lesquels peuvent traiter de nouvelles concernant les Clubs et les adhésions, de promotions (incluant le tirage du grand prix), et d'offres spéciales. À tout moment, je peux choisir de ne plus recevoir ces courriels.",
			rulesHeader: {
				text: "J'ai lu et j'accepte les",
				link: 'Règlements du concours'
			}
		},
		step4: {
			womenHeader: 'AQuel est votre objectif de mise en forme?',
			goals: {
				header: 'Quel est votre objectif de mise en forme?',
				energy: "AUGMENTER MON NIVEAU D'ÉNERGIE",
				strength: 'AUGMENTER MA FORCE MUSCULAIRE',
				weight: 'PERDRE DU POIDS',
				all: 'TOUTES LES RÉPONSES CI-DESSUS'
			},
			complimentary: 'Are you interested in a complimentary 7-day pass?'
		},
		step5: {
			header: "Aimeriez-vous profiter d'un laissez-passer gratuit de 7 jours?",
			contentOne:
				'*En cliquant « Oui », vous acceptez que GoodLife vous contacte par téléphone, message SMS, ou par courriel à des fins promotionnelles, de marketing, pour vous faire part d’offres spéciales, et autres communications qui pourraient vous intéresser.',
			contentTwo:
				'Vous pourrez vous désabonner à tout moment. **Lorsque vous aurez soumis votre requête, GoodLife communiquera avec vous dans le but de programmer votre visite dans un club GoodLife. Les visiteurs doivent avoir atteint l’âge de majorité dans la province dans laquelle se trouve la succursale fréquentée (à moins qu’ils ne soient accompagnés d’un parent ou d’un tuteur légal), et pourront s’y entrainer seulement lorsque le personnel est présent. Non transférable. Aucune valeur monétaire. Pour profiter de votre entrainement gratuit, une fois votre visite terminée, un associé GoodLife programmera avec vous un entrainement gratuit qui devra avoir lieu dans la semaine suivant votre visite. À votre arrivée, vous devrez signer un formulaire de décharge de responsabilité. D’autres conditions peuvent s’appliquer. Visitez le goodlifefitness.com ou un club participant pour obtenir les détails et les heures d’exploitation.',
			contentLink: '',
			contentThree:
				''
		},

		step6: {
			header: 'Merci pour votre participation!',
			boldLine1:
				'Vous êtes maintenant inscrit pour courir la chance de gagner le grand prix de notre concours. ',
			line2:
				"Si devenir membre GoodLife vous intéresse, ou si vous avez indiqué que vous aimeriez en savoir plus à propos de nous, un membre de notre personnel communiquera avec vous bientôt. \n \n Un courriel de confirmation a été envoyé à l'adresse indiquée sur votre bulletin de participation.",
			button: 'JOUER À GRATTER & GAGNER'
		},
		step8: {
			scratchHeader:
				'Allez-y, grattez allègrement pour découvrir votre prix instantané!',
			wonHeader: 'Vous avez gagné un',
			claimHeader: 'Avant que tu partes!',
			claimSubheader: 'Avez-vous réclamé votre prix?',
			claimText:
				"Assurez-vous d'obtenir votre prix instantané auprès de n'importe quel ambassadeur GoodLife sur place.",
			claimButton: "J'ai réclamé mon prix"
		}
	}
}
