import LiveLongBanner from 'components/icons/LiveLongBanner'
import React from 'react'
import Dress from 'assets/images/dress.png'
import './style.scss'
import { Link } from 'react-router-dom'
import Layout from 'components/Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { setEventInfo } from 'redux/slices/eventInfo'
import ThatsIcon from 'assets/images/thatsIcon.svg'
import ThatsFrIcon from 'assets/images/thatsFrIcon.png'

import BrokenGoodLife from 'assets/images/brokenGoodlife.svg'
import { useWindowSize } from 'hooks/useWindowSize'
import { setLang } from 'redux/slices/lang'
import { texts } from 'utils/localizedTexts'

const Home = () => {
	const lang = useSelector(state => state.lang)
	const dispatch = useDispatch()
	const [width] = useWindowSize()
	const eventInfo = useSelector(state => state.eventInfo)
	const [langIsEnglish, setLangIsEnglish] = useState(true)
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		const sendPass = async () => {
			try {
				const { data } = await axios.get(
					`/apps/getEventInfo/${eventInfo?.password}`
				)
				dispatch(setEventInfo(data.data))
				setIsLoading(false)
			} catch (err) {
				console.log(err)
				setIsLoading(false)
				throw Error(err)
			}
		}
		const clearCacheData = () => {
			caches.keys().then(names => {
				names.forEach(name => {
					caches.delete(name)
				})
			})
			console.log('Complete Cache Cleared')
		}
		localStorage.clear()
		clearCacheData()
		sendPass()
	}, [])

	return (
		<Layout style={width <= 766 ? { padding: '0' } : {}} isHome={true}>
			<div className='home'>
				<div className='header'>
					<img src={lang === 'en' ? ThatsIcon : ThatsFrIcon} />
					<button
						onClick={() => dispatch(setLang(lang === 'fr' ? 'en' : 'fr'))}
						className={lang === 'fr' && 'active'}>
						<span>EN</span>
						<span>FR</span>
					</button>
				</div>
				<div className='content'>
					<img src={BrokenGoodLife} />
					<h4 style={lang === 'fr' ? { fontSize: '87px' } : {}}>
						{texts[lang].home.title}
					</h4>
					<h5>{texts[lang].home.subtitle}</h5>
					<h6>{texts[lang].home.prizeInfo}</h6>
					<Link to={'/step1'}>{texts[lang].home.button}</Link>
				</div>
				<p>{texts[lang].home.description}</p>
			</div>
		</Layout>
	)
}

export default Home
